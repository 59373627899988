<template>
  <v-row>
    <v-dialog
      transition="scale-transition"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      scrollable
      v-model="isDialog"
      max-width="700px"
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          {{ $t("extracuricular.dialog.master_extracurricular") }}
          <div style="position: absolute; right: 25px">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form
            ref="masterExtra"
            v-model="formMasterExtra"
            class="mt-2"
            lazy-validation
            @submit.prevent="addMaster"
          >
            <v-text-field
              v-model="masterValue"
              :label="$t('extracuricular.dialog.new_extracurricular')"
              :rules="[v => !!v || $t('app.required')]"
              :hide-details="formMasterExtra"
              dense
              outlined
              required
            />
            <div class="d-flex justify-end" :class="formMasterExtra && 'my-4'">
              <v-btn class="mr-2" outlined color="primary" @click="closeDialog">
                {{ $t("app.back") }}
              </v-btn>
              <v-btn
                :loading="loadingBtn"
                outlined
                class="gradient-primary"
                dark
                @click="addMaster"
                >{{ $t("app.save") }}</v-btn
              >
            </div>
          </v-form>

          <v-data-table
            :headers="headerMasterExtra"
            :items="masterExtra"
            :loading="loading"
            fixed-header
            disable-pagination
            hide-default-footer
            class="elevation-0"
          >
            <template v-slot:item.action="{ item }">
              <v-btn icon dark class="primary mr-2" @click="editMaster(item)">
                <v-icon>mdi-pencil-box-outline</v-icon>
              </v-btn>

              <v-btn icon dark class="primary" @click="deleteMaster(item)">
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-dialog v-model="dialogEditMaster" max-width="500px">
          <v-card>
            <v-form
              ref="editMasterExtra"
              v-model="formEditMasterExtra"
              lazy-validation
            >
              <v-card-text>
                <v-text-field
                  v-model="masterEditable.name"
                  :rules="[v => !!v || $t('app.required')]"
                  :hide-details="formEditMasterExtra"
                  dense
                  outlined
                  required
                />
                <div
                  class="d-flex justify-end"
                  :class="formEditMasterExtra && 'mt-5'"
                >
                  <v-btn
                    class="mr-2"
                    outlined
                    color="primary"
                    @click="closeEdit"
                    >{{ $t("app.back") }}</v-btn
                  >
                  <v-btn
                    outlined
                    class="gradient-primary"
                    dark
                    :loading="loadingBtn"
                    @click="saveEdit"
                    >{{ $t("app.save") }}</v-btn
                  >
                </div>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  getMasterExt,
  postMasterExt,
  updateMasterExt,
  deleteMasterExt
} from "@/api/admin/academic/extracurricular";

export default {
  props: {
    isDialog: {
      type: Boolean,
      required: true
    }
  },
  created() {
    this.initData();
  },
  data() {
    return {
      headerMasterExtra: [
        {
          text: this.$i18n.t("extracuricular.dialog.extracurricular_available"),
          align: "left",
          value: "name"
        },
        {
          text: this.$i18n.t("master_data.student.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      masterExtra: [],
      loading: false,
      dialogEditMaster: false,
      formMasterExtra: false,
      formEditMasterExtra: false,
      masterValue: "",
      masterEditable: {},
      loadingBtn: false
    };
  },
  methods: {
    initData() {
      this.loading = true;
      getMasterExt().then(res => {
        const r = res.data.data;
        this.$store.commit("GET_MASTER_EXTRACURRICULAR", r);
        this.masterExtra = r;
        this.loading = false;
      });
    },
    closeDialog() {
      this.$emit("toggleDialog");
      this.$refs.masterExtra.reset();
    },
    addMaster() {
      if (this.$refs.masterExtra.validate()) {
        this.loadingBtn = true;
        postMasterExt({ name: this.masterValue }).then(res => {
          if (res.data.code === 0) {
            this.snackBar(false, res.data.message);
          }
          this.$refs.masterExtra.reset();
          this.loadingBtn = false;
          this.initData();
        });
      }
    },
    editMaster(item) {
      this.masterEditable = { ...item };
      this.dialogEditMaster = true;
    },
    deleteMaster(item) {
      if (confirm(this.$i18n.t("app.confirm_delete"))) {
        this.loading = true;
        deleteMasterExt(item).then(() => {
          this.initData();
          this.loading = false;
        });
      }
    },
    closeEdit() {
      this.dialogEditMaster = false;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    saveEdit() {
      this.loadingBtn = true;
      if (this.$refs.editMasterExtra.validate()) {
        this.loading = true;
        updateMasterExt(this.masterEditable).then(res => {
          if (res.data.code === 0) {
            this.snackBar(false, res.data.message);
          }
          this.initData();
          this.closeEdit();
          this.loadingBtn = false;
          this.loading = false;
        });
      }
    }
  }
};
</script>
